<template>
  <div>
    <b-card class="">
      <b-row>
        <b-col cols="12" sm="4" class="d-flex justify-content-end ml-auto">
          <b-button
            v-b-modal.modalPrint
            :disabled="!calculatedBefore"
            variant="outline-primary"
            size="sm"
            class="mr-2 ml-auto"
          >
            <feather-icon icon="PrinterIcon" />
            {{ $t("print") }}
          </b-button>
        </b-col>
      </b-row>
      <div class="overflow-hidden d-flex flex-wrap justify-content-between">
        <div class="table-col" cols="12" lg="5">
          <h4>{{ $t("formula") }}</h4>

          <div class="overflow-card custom-scrollbar">
            <b-table
              sticky-header
              class="w-100 os"
              :fields="formulasFields"
              :items="formulas"
            >
              <template #cell(nutrientname)="{ item }">
                <span class="text-small"> {{ item.nutrientname }} </span>
              </template>

              <template #cell(amount)="{ item }">
                <div class="d-flex justify-content-end">
                  <b-form-input
                    v-model="item.amount"
                    type="number"
                    min="0"
                    step="0.01"
                    style="text-align: end; max-width: 150px; width: 150px"
                  />
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div class="my-lg-0 my-2 centerTable" cols="12" lg="2">
          <div class="d-flex justify-content-between align-items-center">
            <b-button
              variant="primary"
              :disabled="!hammaddeler"
              class="d-flex"
              @click="verify"
            >
              <feather-icon icon="CheckIcon"></feather-icon>
              <span>{{ $t("calculate") }}</span>
            </b-button>
          </div>
        </div>
        <div class="table-col">
          <h4>{{ $t("ingredients") }}</h4>

          <div class="overflow-card custom-scrollbar">
            <b-table
              ref="iTable"
              sticky-header
              class="w-100 os"
              :fields="ingFields"
              :items="hammaddeler"
              :sort-by="sortBy"
              :sort-desc="true"
              @sort-changed="onSortChanged"
            >
              <template #cell(rationname)="{ item }">
                <span class="text-small">
                  {{ item.rationname }}
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      id="modalPrint"
      :title="$t('printOptions')"
      centered
      size="xl"
      hide-footer
      class="modal-lg"
    >
      <print-modal
        :formula-data="{
          raporbilgileri: [{}],
          formuller: formulas,
          hammaddeler: hammaddeler,
        }"
      ></print-modal>
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BTable, BButton, BFormInput } from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
import PrintModal from "./Components/IngredientCalcPrintModal.vue";
export default {
  components: {
    BCard,
    PrintModal,
    BTable,
    BButton,
    BFormInput,
    BRow,
    BCol,
  },
  data() {
    return {
      sortBy: "",
      calculatedBefore: false,
      ingFields: [
        {
          key: "ingredientname",
          label: this.$t("ingredientName"),
          sortable: true,
        },

        {
          key: "amount",
          label: this.$t("amount") + " (Kg)",
          sortable: true,
          tdClass: "text-right mw-300",
          thClass: "text-right mw-300",
          formatter: (value) => {
            return value / 1000;
          },
        },
      ],
      formulasFields: [
        {
          key: "rationname",
          label: this.$t("formulaName"),
          sortable: true,
        },
        {
          key: "amount",
          label: this.$t("amount") + " (Kg)",
          sortable: true,
          tdClass: "text-right mw-300",
          thClass: "text-right mw-300",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      formulasIngredients: (state) => state.analyzesModule.formulasIngredients,
      selectedPlantId: (state) => state.app.selectedPlantId,
      formulas: (state) => state.analyzesModule.formulas,
      hammaddeler: (state) => state.analyzesModule.hammaddeler,
    }),
  },
  mounted() {
    this.fetchAnalyzesFormulasAndIngredients(this.selectedPlantId);
  },
  methods: {
    ...mapActions({
      fetchAnalyzesFormulasAndIngredients:
        "analyzesModule/fetchAnalyzesFormulasAndIngredients",
      calculateAmounts: "analyzesModule/calculateAmounts",
    }),
    onSortChanged(column, event) {},
    async verify() {
      const changedItems = this.formulas
        .filter((item) => item.amount !== 0)
        .map((x) => ({
          id: x.id,
          deger: x.amount,
        }));

      if (changedItems.length === 0) {
        this.$toast(this.$t("atLeastOneFormulaNeeded"), {
          title: this.$t("error"),
          variant: "danger",
          solid: true,
          autoHideDelay: 5000,
        });
      } else {
        await this.calculateAmounts({
          values: changedItems,
          plantid: this.selectedPlantId,
        });
        this.calculatedBefore = true;
      }
      this.sortBy = "amount";
      this.$refs.iTable.$emit("sort-changed", {
        field: "amount",
        sortDesc: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-card {
  overflow-y: auto;
  height: calc(100vh - 200px);
  border-radius: 0px 0 10px 10px;
  border-top: 0;
  padding: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.centerTable {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 1280px) {
    width: 10%;
  }
}

.table-col {
  @media screen and (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
  @media screen and (min-width: 1280px) {
    width: 44%;
  }
  width: 39%;
}
.text-small {
  font-size: 0.9rem;
  font-weight: 900;
}
// custom scrollbar
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  padding: 0;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 12px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #817e7e;
  border-radius: 12px;
  transition: 300ms ease-in-out all;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #afafaf;
}
</style>
<style>
.os {
  overflow-y: auto;
  max-height: 100%;
  margin-bottom: 0;
}
.stop {
  position: sticky !important;
  top: 38px !important;
  z-index: 3 !important;
}

.oss {
  position: sticky;
  top: 0px !important;
  z-index: 4 !important;
}
</style>
