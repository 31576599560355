var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{},[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end ml-auto",attrs:{"cols":"12","sm":"4"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalPrint",modifiers:{"modalPrint":true}}],staticClass:"mr-2 ml-auto",attrs:{"disabled":!_vm.calculatedBefore,"variant":"outline-primary","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon"}}),_vm._v(" "+_vm._s(_vm.$t("print"))+" ")],1)],1)],1),_c('div',{staticClass:"overflow-hidden d-flex flex-wrap justify-content-between"},[_c('div',{staticClass:"table-col",attrs:{"cols":"12","lg":"5"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("formula")))]),_c('div',{staticClass:"overflow-card custom-scrollbar"},[_c('b-table',{staticClass:"w-100 os",attrs:{"sticky-header":"","fields":_vm.formulasFields,"items":_vm.formulas},scopedSlots:_vm._u([{key:"cell(nutrientname)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-small"},[_vm._v(" "+_vm._s(item.nutrientname)+" ")])]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-form-input',{staticStyle:{"text-align":"end","max-width":"150px","width":"150px"},attrs:{"type":"number","min":"0","step":"0.01"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1)]}}])})],1)]),_c('div',{staticClass:"my-lg-0 my-2 centerTable",attrs:{"cols":"12","lg":"2"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('b-button',{staticClass:"d-flex",attrs:{"variant":"primary","disabled":!_vm.hammaddeler},on:{"click":_vm.verify}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("calculate")))])],1)],1)]),_c('div',{staticClass:"table-col"},[_c('h4',[_vm._v(_vm._s(_vm.$t("ingredients")))]),_c('div',{staticClass:"overflow-card custom-scrollbar"},[_c('b-table',{ref:"iTable",staticClass:"w-100 os",attrs:{"sticky-header":"","fields":_vm.ingFields,"items":_vm.hammaddeler,"sort-by":_vm.sortBy,"sort-desc":true},on:{"sort-changed":_vm.onSortChanged},scopedSlots:_vm._u([{key:"cell(rationname)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-small"},[_vm._v(" "+_vm._s(item.rationname)+" ")])]}}])})],1)])])],1),_c('b-modal',{staticClass:"modal-lg",attrs:{"id":"modalPrint","title":_vm.$t('printOptions'),"centered":"","size":"xl","hide-footer":""}},[_c('print-modal',{attrs:{"formula-data":{
        raporbilgileri: [{}],
        formuller: _vm.formulas,
        hammaddeler: _vm.hammaddeler,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }